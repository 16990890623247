<template>
  <div class="app-install-failed">
    <img src="../../../assets/images/shoplazza/failed.png" class="success" />
    <div class="tips">
      <div v-html="`Oops! ${reason}`"></div>
      <div style="margin-top: 20px;">Get support from Onerway team - merchant@pacypay.com.</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      reason: this.$route.query.m,
    };
  },
  created() {
    this.$store.dispatch('app/setBrandInfo', {});
  },
};
</script>
<style lang="scss" scoped>
.app-install-failed {
  width: fit-content;
  max-width: 660px;
  margin: 0 auto;
  padding-top: 130px;
  .success {
    width: 134px;
  }
  .tips {
    font-size: 18px;
    color: #616D7A;
    line-height: 21px;
    margin-top: 38px;
    text-align: left;
  }
}
</style>
